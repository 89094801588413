<template>
  <PlainCellStyled class="cell" :isMobile="isMobile" :panel="cell.panel">
    <router-link :to="{ name: cell.linkName, params: cell.linkParams }" :title="`${cell.linkLabel}`">
      <CircleIcon size="0.5x" v-if="cell.isActive" class="icon" />
      <div>{{ cell.linkLabel }}</div>
    </router-link>
  </PlainCellStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { CircleIcon } from 'vue-feather-icons'

const PlainCellStyled = styled('div')`
  display: flex;
  align-items: center;
  > a > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 160px;
  }
  @media (min-width: 1024px) {
    padding-left: 0;
    ${({ isMobile, panel }) => !isMobile && !panel && `padding-left: 3rem`};
  }
  .icon {
    fill: ${({ theme }) => theme.colors.atomic.artylickOrange};
    color: ${({ theme }) => theme.colors.atomic.artylickOrange};
    position: absolute;
    left: 7px;
    @media (min-width: 1024px) {
      left: 48px;
    }
  }
  padding: 0.5rem;
`

export default {
  props: {
    cell: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    panel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PlainCellStyled,
    CircleIcon,
  },
}
</script>
