var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PlainCellStyled', {
    staticClass: "cell",
    attrs: {
      "isMobile": _vm.isMobile,
      "panel": _vm.cell.panel
    }
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: _vm.cell.linkName,
        params: _vm.cell.linkParams
      },
      "title": "".concat(_vm.cell.linkLabel)
    }
  }, [_vm.cell.isActive ? _c('CircleIcon', {
    staticClass: "icon",
    attrs: {
      "size": "0.5x"
    }
  }) : _vm._e(), _c('div', [_vm._v(_vm._s(_vm.cell.linkLabel))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }