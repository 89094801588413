<template>
  <QuickFiltersPanelStyled>
    <div v-for="filter in filters" :key="filter.id">
      <QuickFilterButton :filter="filter" @doFilter="$emit('doFilter', $event)" />
    </div>
  </QuickFiltersPanelStyled>
</template>
<script>
import QuickFilterButton from '../Atoms/QuickFilterButton'
import { styled } from '@egoist/vue-emotion'
import // flexCenter,
'@styles/mixins'

const QuickFiltersPanelStyled = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export default {
  components: {
    QuickFiltersPanelStyled,
    QuickFilterButton,
  },
  props: {
    filters: {
      required: true,
      type: Array,
    },
  },
  methods: {},
}
</script>
