<template>
  <TalpaLoaderWrapperStyled v-if="isLoading" />
  <NoDataStyled v-else-if="dashboards.length === 0">
    <i18n path="messages.noDataWithType">
      <template v-slot:type>
        {{ $tc('types.dashboard', 2) }}
      </template>
    </i18n>
  </NoDataStyled>
  <DashboardListStyled v-else>
    <TableV2
      class="dashboard-list"
      :title="''"
      :headers="headers"
      :rows="rows"
      :sorting.sync="sorting"
      :filtersAvailable="filtersAvailable"
      :filterOptionsSelected="filterOptionsSelected"
      :isLoading="$apollo.loading"
      :searchQuery="searchQuery"
      :enableSearch="true"
      :enableFooter="false"
      :messages="messages"
      @setSelectedFilterOption="setSelectedFilterOption"
      @resetFilters="resetFilters"
      @textFilter="textSearch"
    />
  </DashboardListStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import { getUserIdFromToken } from '@common/utils'
import DASHBOARDS_QUERY from '#/graphql/operations/dashboards/dashboards.gql'
import DASHBOARDS_MINIMAL from '#/graphql/operations/dashboards/dashboardsMinimal.gql'
import DELETE_DASHBOARD_MUTATION from '#/graphql/operations/dashboard/deleteDashboard.gql'
import DELETE_DASHBOARD_SHARE_MUTATION from '#/graphql/operations/dashboard/share/deleteDashboardShare.gql'
import { flexCenter } from '@styles/mixins'
import TableV2 from '@/components/Atomic/Molecules/TableV2.vue'
import TableRouterLinkCell from '@/components/Atomic/Atoms/TableCells/DashboardTableRouterLinkCell.vue'
import TableActionsCell from '@/components/Atomic/Atoms/TableCells/TableActionsCell.vue'
import DashboardTableShareCell from '@/components/Atomic/Atoms/TableCells/DashboardTableShareCell.vue'
import { TABLE_SORT_TYPES } from '../../../constants/settings'
import { compact, concat, groupBy, orderBy, partition, uniqBy } from 'lodash'
import permissionsMixin from '@/mixins/permissions'
import localesMixin from '@/mixins/locales'
import { TimeframeMixin } from '@common/mixins'
import { TalpaLoaderWrapper } from '@common/components'
import { formatDateAccordingToSettings } from '@/utils/time'

const TalpaLoaderWrapperStyled = styled(TalpaLoaderWrapper)`
  background: none;
  height: 250px;
`

const DashboardListStyled = styled('div')`
  padding: 0.5rem;
  overflow: hidden;
  @media (min-width: 768px) {
    min-height: 30vh;
  }
`
const NoDataStyled = styled('div')`
  ${flexCenter}
  padding: .5rem;
  height: 250px;
  @media (min-width: 768px) {
    padding: 2rem;
  }
`
export default {
  inject: ['uiSettings', 'permissions'],
  mixins: [permissionsMixin, localesMixin, TimeframeMixin],
  props: {
    dashboards: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    hasDashboardShareFeature: {
      type: Boolean,
      require: false,
      default: false,
    },
    machinesRecordCount: {
      type: Number,
      require: true,
    },
  },
  components: {
    DashboardListStyled,
    NoDataStyled,
    TalpaLoaderWrapperStyled,
    TableV2,
  },
  data() {
    return {
      sorting: {
        key: 'title',
        asc: true,
      },
      filterOptionsSelected: [],
      searchQuery: '',
    }
  },
  created() {
    this.$root.$on('performDashboardAction', this.performAction)
  },
  computed: {
    uniqueDashboardTitles() {
      return uniqBy(this.dashboardsMapped, 'title').map(dashboard => {
        return { name: dashboard.title, __typename: 'Title' }
      })
    },
    uniqueDashboardTypes() {
      return uniqBy(this.dashboardsMapped, 'type').map(dashboard => {
        return { name: dashboard.type, __typename: 'Type' }
      })
    },
    dashboardsMapped() {
      return this.dashboards.map(dashboard => ({
        ...dashboard,
        isDashboardOptionMenuActive: false,
        assetCount: get(dashboard, ['assets', 'length'], 0) > 0 ? dashboard.assets?.length : this.machinesRecordCount,
      }))
    },
    dashboardRead() {
      return get(this.permissions, 'read', []).find(p => p.name === 'dashboard_read')
    },
    dashboardWrite() {
      return get(this.permissions, 'write', []).find(p => p.name === 'dashboard_write')
    },
    filtersAvailable() {
      const filters = [
        {
          id: 'filter_dashboard_title',
          class: 'filter filter-dashboard-title',
          modelName: 'Title',
          placeholder: this.$tc('name', 2),
          searchable: false,
          options: orderBy(
            this.uniqueDashboardTitles.map(m => ({
              ...m,
              id: m.name + '',
              label: m.name,
              sortingLabel: m.name.toLowerCase(),
            })),
            'sortingLabel',
            'asc',
          ),
          value: this.filterOptionsSelected.filter(f => f.__typename === 'Title'),
          isMultiple: true,
        },
        {
          id: 'filter_dashboard_type',
          class: 'filter filter-dashboard-type',
          modelName: 'Type',
          placeholder: this.$tc('dashboard.types.placeholder'),
          searchable: false,
          options: orderBy(
            this.uniqueDashboardTypes.map(m => ({
              ...m,
              id: m.name + '',
              label: this.$t(`dashboard.types.${m?.name?.toLowerCase()}`),
              sortingLabel: m.name.toLowerCase(),
            })),
            'sortingLabel',
            'asc',
          ),
          value: this.filterOptionsSelected.filter(f => f.__typename === 'Type'),
          isMultiple: true,
        },
      ]
      return filters
    },
    dashboardsFiltered() {
      if (this.filterOptionsSelected.length > 0) {
        return this.dashboardsMapped.reduce((dashboardsFiltered, dashboard) => {
          const filterGroups = groupBy(this.filterOptionsSelected, '__typename')
          const notInAllGroups = Object.keys(filterGroups).some(key => {
            const filterGroup = filterGroups[key]
            let dashboardPath
            let filterPath
            if (key === 'Title') {
              dashboardPath = 'title'
              filterPath = 'id'
            } else if (key === 'Type') {
              dashboardPath = 'type'
              filterPath = 'id'
            } else {
              throw new Error(`unhandled filter type ${key}`)
            }
            const found = filterGroup.find(filter => get(filter, filterPath) === get(dashboard, dashboardPath))
            return !found
          })
          if (!notInAllGroups) {
            dashboardsFiltered.push(dashboard)
          }
          return dashboardsFiltered
        }, [])
      }
      return this.dashboardsMapped
    },
    dashboardSorted() {
      const partitions = partition(this.dashboardsFiltered, x => !!get(x, this.sorting.key, null))
      const sortDirection =
        this.sorting.key === 'createdAt' || this.sorting.key === 'updatedAt'
          ? this.sorting.asc
            ? 'desc'
            : 'asc'
          : this.sorting.asc
          ? 'asc'
          : 'desc'
      const key = this.sorting.key === 'title' ? [item => get(item, this.sorting.key).toLowerCase()] : [this.sorting.key]
      return concat(orderBy(partitions[0], key, [sortDirection]), partitions[1])
    },
    headers() {
      const headers = [
        {
          id: `header_name`,
          label: this.$tc('name', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'title',
          size: 'medium',
        },
        {
          id: `header_asset`,
          label: this.$tc('asset', 2),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'assetCount',
          size: 'xsmall',
        },
        {
          id: `header_widget`,
          label: this.$tc('widget', 2),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'gridItems',
          size: 'xsmall',
        },
        {
          id: `header_type`,
          label: this.$tc('type', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'type',
          size: 'small',
        },
        {
          id: `header_created`,
          label: this.$tc('createdAt'),
          sortType: TABLE_SORT_TYPES.NEW_TO_OLD,
          isSortable: true,
          sortKey: 'createdAt',
          size: 'medium',
        },
        {
          id: `header_updated`,
          label: this.$tc('updatedAt'),
          sortType: TABLE_SORT_TYPES.NEW_TO_OLD,
          isSortable: true,
          sortKey: 'updatedAt',
          size: 'medium',
        },

        {
          id: `header_shares`,
          label: this.$tc('dashboard.attributes.sharedWith', 1),
          sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
          isSortable: true,
          sortKey: 'shares',
          size: 'small',
        },
        {
          id: `header_tools`,
          label: this.$tc('dashboard.tools.tool', 2),
          isSortable: false,
          size: 'xsmall',
        },
      ]
      return headers
    },
    rows() {
      const machinesCount = this.machinesRecordCount
      const rows = this.dashboardSorted.map(dashboard => {
        let cells = [
          {
            id: `${dashboard.id}_name`,
            sortableValue: dashboard?.title,
            component: TableRouterLinkCell,
            linkName: 'dashboard',
            linkLabel: dashboard?.title,
            linkParams: { id: dashboard.id },
            isActive: dashboard?.shares?.length > 0,
            isMobilePrimarycol: true,
            mobilePrimarycolLabel: dashboard?.title,
            headerId: `header_name`,
          },
          {
            id: `${dashboard.id}_asset`,
            sortableValue: dashboard?.assetCount === machinesCount ? this.$t('all') : dashboard?.assetCount,
            headerId: `header_asset`,
          },
          {
            id: `${dashboard.id}_widget`,
            sortableValue: dashboard?.gridItems.length,
            headerId: `header_widget`,
          },
          {
            id: `${dashboard.id}_type`,
            sortableValue: this.$t(`dashboard.types.${dashboard?.type?.toLowerCase()}`),
            headerId: `header_type`,
          },
          {
            id: `${dashboard.id}_created`,
            sortableValue: formatDateAccordingToSettings(dashboard?.createdAt, this.uiSettings, this.selectedTimezone),
            headerId: `header_created`,
          },
          {
            id: `${dashboard.id}_updated`,
            sortableValue: formatDateAccordingToSettings(dashboard?.updatedAt, this.uiSettings, this.selectedTimezone),
            headerId: `header_updated`,
          },

          {
            id: `${dashboard.id}_shares`,
            sortableValue: dashboard?.shares.length,
            component: DashboardTableShareCell,
            dashboard: dashboard,
            label: dashboard?.shares.length,
            headerId: `header_shares`,
          },
          {
            id: `${dashboard.id}_tools`,
            component: TableActionsCell,
            dashboard: dashboard,
            isOwner: this.isOwner(dashboard?.ownerUserId),
            hasDashboardShareFeature: this.hasDashboardShareFeature,
            headerId: `header_tools`,
          },
        ]
        return {
          rowId: `row_${dashboard.id}`,
          cells,
        }
      })

      const filteredTable = rows.filter(row => {
        const props = compact(row.cells.map(item => item.sortableValue))
        return props.some(
          prop =>
            !this.searchQuery ||
            (typeof prop === 'string'
              ? prop.toLowerCase().includes(this.searchQuery.toLowerCase())
              : prop.toString(10).includes(this.searchQuery)),
        )
      })
      return filteredTable
    },
    messages() {
      let message = ''
      if (this.rows.length === 0) {
        message =
          this.searchQuery === ''
            ? this.$tc('messages.noDataForSelectedTime')
            : this.$t('messages.searchNoResults', { query: this.searchQuery })
      }
      return message
    },
  },

  methods: {
    editDashboard(dashboard) {
      this.$root.$emit('activateOverlay', 'DashboardSettingsOverlay', dashboard)
    },
    confirmDeleteDashboard(dashboard) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'Dashboard',
        onConfirm: this.deleteDashboard,
        onConfirmArgs: dashboard,
      })
    },
    confirmUnsubscribeDashboard(dashboard) {
      this.$root.$emit('activateOverlay', 'ConfirmDeleteOverlay', {
        type: 'Dashboard_share',
        onConfirm: this.unsubscribeDashboard,
        onConfirmArgs: dashboard,
      })
    },
    async unsubscribeDashboard(dashboard) {
      const dashboardShare = get(dashboard, 'shares', []).find(s => s.sharedWithUserId === getUserIdFromToken(this.$keycloak.token))
      const res = await this.$apollo.mutate({
        mutation: DELETE_DASHBOARD_SHARE_MUTATION,
        variables: {
          where: {
            id: get(dashboardShare, 'id', ''),
          },
        },
        update: store => {
          const data = store.readQuery({
            query: DASHBOARDS_QUERY,
          })
          data.dashboards = data.dashboards.filter(d => d.id !== dashboard.id)
          store.writeQuery({ query: DASHBOARDS_QUERY, data })
          let minimalData = store.readQuery({
            query: DASHBOARDS_QUERY,
          })
          minimalData.dashboards = minimalData.dashboards.filter(d => d.id !== dashboard.id)
          store.writeQuery({
            query: DASHBOARDS_MINIMAL,
            data: minimalData,
          })
        },
      })
      return res
    },
    async deleteDashboard({ id }) {
      const res = await this.$apollo.mutate({
        mutation: DELETE_DASHBOARD_MUTATION,
        variables: {
          where: {
            id,
          },
        },
        update: (store, { data: { deleteDashboard } }) => {
          let data = store.readQuery({
            query: DASHBOARDS_QUERY,
          })
          data.dashboards = data.dashboards.filter(d => d.id !== deleteDashboard.id)
          store.writeQuery({
            query: DASHBOARDS_QUERY,
            data,
          })
          let minimalData = store.readQuery({
            query: DASHBOARDS_QUERY,
          })
          minimalData.dashboards = minimalData.dashboards.filter(d => d.id !== deleteDashboard.id)
          store.writeQuery({
            query: DASHBOARDS_MINIMAL,
            data: minimalData,
          })
        },
      })
      return res
    },
    performAction(action, dashboard) {
      this.showPopup = ''
      switch (action) {
        case 'settings':
          this.editDashboard(dashboard)
          break
        case 'duplicate':
          break
        case 'delete':
          if (this.isOwner(dashboard.ownerUserId)) {
            this.confirmDeleteDashboard(dashboard)
          } else {
            this.confirmUnsubscribeDashboard(dashboard)
          }
          break
        case 'share':
          this.$root.$emit('activateOverlay', 'DashboardShareOverlay', dashboard)
          break
        default:
          break
      }
    },
    isOwner(id) {
      return this.$keycloak.subject === id
    },
    getOwner(dashboard) {
      return dashboard.ownerUserId === this.$keycloak.subject ? null : dashboard.owner
    },
    textSearch(query) {
      this.searchQuery = query
    },
    setSelectedFilterOption(filter) {
      const found = this.filterOptionsSelected.find(f => f.id === filter.id)
      if (found) {
        this.filterOptionsSelected = this.filterOptionsSelected.filter(f => f.id !== filter.id)
      } else {
        this.filterOptionsSelected.push(filter)
      }
    },
    resetFilters() {
      this.filterOptionsSelected = []
    },
  },
}
</script>
