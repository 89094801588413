var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('OverviewOptionsStyled', [_c('OverviewOption', {
    attrs: {
      "disabled": !_vm.isShareEnabled || !_vm.isOwner
    },
    on: {
      "click": function click($event) {
        return _vm.emitAction(1);
      }
    }
  }, [_c('ShareIcon', {
    staticClass: "icon"
  }), _vm._v(" " + _vm._s(_vm.$t('actions.share')) + " ")], 1), _c('OverviewOption', {
    staticClass: "border",
    attrs: {
      "disabled": !_vm.isOwner
    },
    on: {
      "click": function click($event) {
        return _vm.emitAction(2);
      }
    }
  }, [_c('SettingsIcon', {
    staticClass: "icon"
  }), _c('span', {
    staticClass: "align"
  }, [_vm._v(" " + _vm._s(_vm.$t('dashboard.dashboardSettings')) + " ")])], 1), _c('OverviewOption', {
    attrs: {
      "disabled": false
    },
    on: {
      "click": function click($event) {
        return _vm.emitAction(3);
      }
    }
  }, [_c('TrashIcon', {
    staticClass: "icon"
  }), _vm.isOwner ? _c('span', [_vm._v(_vm._s(_vm.$t('actions.delete')))]) : _c('span', [_vm._v(_vm._s(_vm.$t('actions.unsubscribe')))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }