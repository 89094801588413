<template>
  <DashboardQuickFiltersStyled>
    <QuickFiltersPanel :filters="filters" @doFilter="$emit('doFilter', $event)" />
  </DashboardQuickFiltersStyled>
</template>
<script>
import QuickFiltersPanel from '@/components/Atomic/Molecules/QuickFiltersPanel'
import { styled } from '@egoist/vue-emotion'
import // flexCenter,
'@styles/mixins'

const DashboardQuickFiltersStyled = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export default {
  components: {
    DashboardQuickFiltersStyled,
    QuickFiltersPanel,
  },
  props: {
    filters: {
      required: true,
      type: Array,
    },
  },
  methods: {},
}
</script>
