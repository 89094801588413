var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('QuickFilterStyled', {
    attrs: {
      "selected": _vm.filter.isSelected
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('doFilter', _vm.filter);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.filter.name) + " "), _vm.filter.count ? _c('CircleBadge', {
    attrs: {
      "label": _vm.filter.count,
      "isSelected": _vm.filter.isSelected,
      "marginLeft": '.5rem',
      "size": 'S'
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }