var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isLoading ? _c('TalpaLoaderWrapperStyled') : _vm.dashboards.length === 0 ? _c('NoDataStyled', [_c('i18n', {
    attrs: {
      "path": "messages.noDataWithType"
    },
    scopedSlots: _vm._u([{
      key: "type",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$tc('types.dashboard', 2)) + " ")];
      },
      proxy: true
    }])
  })], 1) : _c('DashboardListStyled', [_c('TableV2', {
    staticClass: "dashboard-list",
    attrs: {
      "title": '',
      "headers": _vm.headers,
      "rows": _vm.rows,
      "sorting": _vm.sorting,
      "filtersAvailable": _vm.filtersAvailable,
      "filterOptionsSelected": _vm.filterOptionsSelected,
      "isLoading": _vm.$apollo.loading,
      "searchQuery": _vm.searchQuery,
      "enableSearch": true,
      "enableFooter": false,
      "messages": _vm.messages
    },
    on: {
      "update:sorting": function updateSorting($event) {
        _vm.sorting = $event;
      },
      "setSelectedFilterOption": _vm.setSelectedFilterOption,
      "resetFilters": _vm.resetFilters,
      "textFilter": _vm.textSearch
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }