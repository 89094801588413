<template>
  <QuickFilterStyled :selected="filter.isSelected" @click="$emit('doFilter', filter)">
    {{ filter.name }}
    <CircleBadge v-if="filter.count" :label="filter.count" :isSelected="filter.isSelected" :marginLeft="'.5rem'" :size="'S'" />
  </QuickFilterStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import CircleBadge from '@/components/Atomic/Atoms/CircleBadge'
import { flexCenter } from '@styles/mixins'
// import chroma from 'chroma-js'

const QuickFilterStyled = styled('button')`
  border: none;
  border-radius: 8px;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.atomic.white : theme.isDark ? theme.colors.white : theme.colors.navFontNormal};
  background: ${({ theme, selected }) => (selected ? theme.colors.atomic.primary : theme.colors.solidBG)};
  height: 35px;
  min-width: 35px;
  padding: 0.25rem 0.5rem;
  margin-right: 0.25rem;
  ${flexCenter}
  cursor: pointer;
  .count {
    ${flexCenter}
    background: #FFFFFF4D;
    width: 1rem;
    height: 1rem;
    padding: 0.25rem;
    border-radius: 50%;
    margin-left: 0.5rem;
  }
`

export default {
  components: {
    QuickFilterStyled,
    CircleBadge,
  },
  props: {
    filter: {
      required: true,
      type: Object,
    },
  },
  methods: {},
}
</script>
