<template>
  <DashboardShareIndicatorStyled>
    <VPopover
      :placement="'top-start'"
      :popperOptions="popoverOptions"
      :container="'#app'"
      :trigger="'hover'"
      :disabled="owner !== null"
      :popoverClass="'dashboardSharePopOver'"
      :offset="10"
    >
      <div class="shared">
        <sharedUserIcon />
      </div>
      <template v-slot:popover>
        <PopoverItem>
          <div class="not-owner">
            {{ $t('dashboard.dashboardSharedMessage') }}
          </div>
        </PopoverItem>
      </template>
    </VPopover>
    <VPopover
      :placement="'top-start'"
      :popperOptions="popoverOptions"
      :container="'#app'"
      :trigger="'hover'"
      :popoverClass="'dashboardSharePopOver'"
      :offset="10"
      v-if="owner"
      class="owner"
    >
      <div>
        <AvatarBadge :size="'30px'" :fontSize="'14px'" :userData="owner" :isOwner="true" />
      </div>
      <template v-slot:popover>
        <PopoverItem>
          <AvatarBadge :size="'30px'" :fontSize="'14px'" :userData="owner" :isOwner="true" />
          <div class="user-data">
            <div id="sensitive">{{ fullName }}</div>
            <div id="sensitive">{{ userEmail }}</div>
          </div>
          <OwnerBadge />
        </PopoverItem>
      </template>
    </VPopover>
  </DashboardShareIndicatorStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import sharedUserIcon from '@/assets/icons/sharedUserIcon.svg'
import localesMixin from '@/mixins/locales'
import AvatarBadge from '@/components/Atomic/Atoms/AvatarBadge'
import { VPopover } from 'v-tooltip'
import get from 'lodash/get'
import OwnerBadge from '@/components/Atomic/Atoms/OwnerBadge'

import { flexCenter } from '@styles/mixins'

const DashboardShareIndicatorStyled = styled('div')`
  width: fit-content;
  display: flex;
  .shared {
    ${flexCenter}
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.atomic.primary};
    svg {
      width: 75%;
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }
  .owner {
    margin-left: 0.25rem;
  }
`
const PopoverItem = styled('div')`
  ${flexCenter}
  background: ${({ theme }) => theme.colors.atomic.feedListItemBg};
  color: ${({ theme }) => theme.colors.atomic.textSecondary};
  font-size: 12px;
  padding: 0.25rem 0.5rem;
  border-radius: 8px;
  .not-owner {
    margin: 0.125rem 0.25rem;
  }
  div {
    margin: 0.125rem 0.25rem;
  }
`

export default {
  mixins: [localesMixin],
  components: {
    DashboardShareIndicatorStyled,
    sharedUserIcon,
    AvatarBadge,
    VPopover,
    PopoverItem,
    OwnerBadge,
  },
  data() {
    return {}
  },
  props: {
    owner: {
      type: Object,
      required: false,
    },
  },
  computed: {
    popoverOptions() {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      }
    },
    fullName() {
      const firstName = get(this.owner, 'firstName', null)
      const lastName = get(this.owner, 'lastName', null)
      const name = firstName && lastName ? firstName + ' ' + lastName : null
      return name !== null ? name : get(this.owner, 'username', '')
    },
    userEmail() {
      return get(this.owner, 'email', '')
    },
  },
  methods: {},
}
</script>
