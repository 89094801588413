var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DashboardsStyled', [_c('HeaderStyled', [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t('feed.dashboard.title')))]), _c('div', {
    staticClass: "filters"
  }, [_c('DashboardQuickFilters', {
    attrs: {
      "filters": _vm.dashboardFilters
    },
    on: {
      "doFilter": _vm.applyFilter
    }
  })], 1), _c('div', {
    staticClass: "mode"
  }, [_c('ChoicesStyled', [_c('button', {
    class: {
      active: _vm.isListView
    },
    attrs: {
      "isActive": _vm.isListView
    },
    on: {
      "click": function click($event) {
        return _vm.setDashboardsViewMode('list');
      }
    }
  }, [_c('ListIcon')], 1), _c('button', {
    class: {
      active: _vm.isGridView
    },
    attrs: {
      "isActive": _vm.isGridView
    },
    on: {
      "click": function click($event) {
        return _vm.setDashboardsViewMode('grid');
      }
    }
  }, [_c('GridIcon')], 1)])], 1)]), _vm.isListView ? _c('DashboardList', {
    attrs: {
      "dashboards": _vm.filteredDashboards,
      "isLoading": _vm.$apollo.loading,
      "hasDashboardShareFeature": _vm.hasDashboardShareFeature,
      "machinesRecordCount": _vm.machinesRecordCount
    }
  }) : _c('DashboardGrid', {
    attrs: {
      "dashboards": _vm.filteredDashboards,
      "isLoading": _vm.$apollo.loading,
      "hasDashboardShareFeature": _vm.hasDashboardShareFeature
    }
  }), _c('ActionsStyled', [_vm.dashboardWrite ? _c('ButtonStyled', {
    on: {
      "click": function click($event) {
        return _vm.$root.$emit('activateOverlay', 'DashboardSettingsOverlay', {
          mode: 'add'
        });
      }
    }
  }, [_c('PlusIcon')], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }