var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PlainCellStyled', {
    staticClass: "cell",
    attrs: {
      "isMobile": _vm.isMobile,
      "panel": _vm.cell.panel
    },
    on: {
      "click": function click($event) {
        return _vm.$root.$emit('activateOverlay', 'DashboardShareOverlay', _vm.cell.dashboard);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.cell.label) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }