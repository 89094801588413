var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('QuickFiltersPanelStyled', _vm._l(_vm.filters, function (filter) {
    return _c('div', {
      key: filter.id
    }, [_c('QuickFilterButton', {
      attrs: {
        "filter": filter
      },
      on: {
        "doFilter": function doFilter($event) {
          return _vm.$emit('doFilter', $event);
        }
      }
    })], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }