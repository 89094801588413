<template>
  <PlainCellStyled class="cell" :isMobile="isMobile" :panel="cell.panel">
    <VPopover
      :trigger="'manual'"
      :placement="'bottom-start'"
      :open="showPopup === cell?.dashboard?.id"
      :autoHide="true"
      :popperOptions="popperOptions"
      @hide="hideOptions"
    >
      <MoreHorizontalIcon @click="toggleOptions(cell.dashboard.id)" />
      <template v-slot:popover>
        <DashboardOverviewOptionsMenu
          :isOwner="cell.isOwner"
          :isShareEnabled="cell.hasDashboardShareFeature"
          @onAction="$root.$emit('performDashboardAction', $event, cell.dashboard)"
        />
      </template>
    </VPopover>
  </PlainCellStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { MoreHorizontalIcon } from 'vue-feather-icons'
import DashboardOverviewOptionsMenu from '@/components/Atomic/Molecules/DashboardOverviewOptionsMenu'
import { VPopover } from 'v-tooltip'
import chroma from 'chroma-js'

const PlainCellStyled = styled('div')`
  display: flex;
  align-items: center;
  @media (min-width: 1024px) {
    padding-left: 0;
    ${({ isMobile, panel }) => !isMobile && !panel && `padding-left: 3rem`};
  }
  padding: 0.5rem;

  justify-content: flex-end;
  padding: 10px 10px;
  svg {
    width: 20px;
    height: 20px;
    stroke: ${p => chroma(p.theme.colors.primary).css()};
    cursor: pointer;
    &:hover {
      stroke: ${p => chroma(p.theme.colors.primaryActive).alpha(1).css()};
    }
  }
`

export default {
  props: {
    cell: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    panel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPopup: '',
    }
  },
  components: {
    PlainCellStyled,
    MoreHorizontalIcon,
    DashboardOverviewOptionsMenu,
    VPopover,
  },
  computed: {
    popperOptions() {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: true,
          },
        },
      }
    },
  },
  methods: {
    toggleOptions(id) {
      this.showPopup = ''
      this.showPopup = id
    },
    hideOptions() {
      this.showPopup = ''
    },
  },
}
</script>
