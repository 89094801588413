var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DashboardQuickFiltersStyled', [_c('QuickFiltersPanel', {
    attrs: {
      "filters": _vm.filters
    },
    on: {
      "doFilter": function doFilter($event) {
        return _vm.$emit('doFilter', $event);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }