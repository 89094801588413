<template>
  <OverviewOptionsStyled>
    <OverviewOption :disabled="!isShareEnabled || !isOwner" @click="emitAction(1)">
      <ShareIcon class="icon" />
      {{ $t('actions.share') }}
    </OverviewOption>
    <OverviewOption class="border" :disabled="!isOwner" @click="emitAction(2)">
      <SettingsIcon class="icon" />
      <span class="align"> {{ $t('dashboard.dashboardSettings') }} </span>
    </OverviewOption>
    <OverviewOption :disabled="false" @click="emitAction(3)">
      <TrashIcon class="icon" />
      <span v-if="isOwner">{{ $t('actions.delete') }}</span>
      <span v-else>{{ $t('actions.unsubscribe') }}</span>
    </OverviewOption>
  </OverviewOptionsStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import SettingsIcon from '@/assets/icons/actions/SettingsIcon.svg'
import TrashIcon from '@/assets/icons/actions/TrashIcon.svg'
import ShareIcon from '@/assets/icons/actions/ShareIcon.svg'
import localesMixin from '@/mixins/locales'

const OverviewOptionsStyled = styled('div')`
  width: fit-content;
  min-width: 150px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.atomic.feedListItemBg};
  box-shadow: 0px 8px 30px ${({ theme }) => theme.colors.atomic.boxShadow1};
  font-size: 12px;
  padding: 0.5rem 0.5rem;
  .border {
    border-top: 1px solid ${({ theme }) => theme.colors.atomic.border1};
  }
`
const OverviewOption = styled('button')`
  color: ${({ theme, disabled }) => (disabled ? theme.colors.disabled : theme.colors.atomic.textSecondary)};
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-direction: row;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background: none;
  border: none;
  .icon {
    width: 16px;
    margin-right: 0.5rem;
    > path {
      fill: ${({ theme, disabled }) => (disabled ? theme.colors.disabled : theme.colors.primary)};
    }
  }
  .align {
    text-align: left;
  }
`

export default {
  mixins: [localesMixin],
  components: {
    OverviewOptionsStyled,
    OverviewOption,
    SettingsIcon,
    TrashIcon,
    ShareIcon,
  },
  props: {
    isOwner: {
      type: Boolean,
      default: false,
    },
    isShareEnabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {
    emitAction(action) {
      switch (action) {
        case 1:
          if (this.isOwner) {
            this.$emit('onAction', 'share')
          }
          break
        case 2:
          if (this.isOwner) {
            this.$emit('onAction', 'settings')
          }
          break
        case 3:
          this.$emit('onAction', 'delete')
          break
        default:
          break
      }
    },
  },
}
</script>
