var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isLoading ? _c('TalpaLoaderWrapperStyled') : _vm.dashboards.length === 0 ? _c('NoDataStyled', [_c('i18n', {
    attrs: {
      "path": "messages.noDataWithType"
    },
    scopedSlots: _vm._u([{
      key: "type",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$tc('types.dashboard', 2)) + " ")];
      },
      proxy: true
    }])
  })], 1) : _c('DashboardGridStyled', _vm._l(_vm.dashboards, function (dashboard) {
    return _c('DashboardGridItemStyled', {
      key: dashboard.id
    }, [_c('DashboardTitleStyled', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip",
        value: _vm.tooltip(dashboard.title),
        expression: "tooltip(dashboard.title)"
      }]
    }, [_c('span', [_vm._v(" " + _vm._s(dashboard.title) + " ")])]), _c('PreviewImageStyled', {
      on: {
        "click": function click($event) {
          return _vm.openDashboard(dashboard);
        }
      }
    }, [_vm.getPicture(dashboard) ? _c('img', {
      attrs: {
        "src": _vm.getPicture(dashboard)
      }
    }) : _c('TalpaCockpitIcon', {
      staticClass: "brand",
      attrs: {
        "width": "24",
        "height": "24"
      }
    }), dashboard.shares && dashboard.shares.length > 0 ? _c('SharedIndicator', [_c('DashboardShareIndicator', {
      attrs: {
        "owner": _vm.getOwner(dashboard)
      }
    })], 1) : _vm._e(), _vm.dashboardWrite ? _c('ActionPanel', {
      staticClass: "action-panel",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
        }
      }
    }, [_c('VPopover', {
      attrs: {
        "trigger": 'manual',
        "placement": 'bottom-start',
        "open": _vm.showPopup === dashboard.id,
        "autoHide": true,
        "popperOptions": _vm.popperOptions
      },
      on: {
        "hide": _vm.hideOptions
      },
      scopedSlots: _vm._u([{
        key: "popover",
        fn: function fn() {
          return [_c('DashboardOverviewOptionsMenu', {
            staticClass: "option-menu",
            attrs: {
              "isOwner": dashboard.isOwner,
              "isShareEnabled": _vm.hasDashboardShareFeature
            },
            on: {
              "onAction": function onAction($event) {
                return _vm.performAction($event, dashboard);
              }
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('MoreHorizontalIcon', {
      on: {
        "click": function click($event) {
          return _vm.toggleOptions(dashboard.id);
        }
      }
    })], 1)], 1) : _vm._e()], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }