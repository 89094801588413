var render = function () {
  var _vm$cell, _vm$cell$dashboard;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('PlainCellStyled', {
    staticClass: "cell",
    attrs: {
      "isMobile": _vm.isMobile,
      "panel": _vm.cell.panel
    }
  }, [_c('VPopover', {
    attrs: {
      "trigger": 'manual',
      "placement": 'bottom-start',
      "open": _vm.showPopup === ((_vm$cell = _vm.cell) === null || _vm$cell === void 0 ? void 0 : (_vm$cell$dashboard = _vm$cell.dashboard) === null || _vm$cell$dashboard === void 0 ? void 0 : _vm$cell$dashboard.id),
      "autoHide": true,
      "popperOptions": _vm.popperOptions
    },
    on: {
      "hide": _vm.hideOptions
    },
    scopedSlots: _vm._u([{
      key: "popover",
      fn: function fn() {
        return [_c('DashboardOverviewOptionsMenu', {
          attrs: {
            "isOwner": _vm.cell.isOwner,
            "isShareEnabled": _vm.cell.hasDashboardShareFeature
          },
          on: {
            "onAction": function onAction($event) {
              return _vm.$root.$emit('performDashboardAction', $event, _vm.cell.dashboard);
            }
          }
        })];
      },
      proxy: true
    }])
  }, [_c('MoreHorizontalIcon', {
    on: {
      "click": function click($event) {
        return _vm.toggleOptions(_vm.cell.dashboard.id);
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }