<template>
  <DashboardsStyled>
    <HeaderStyled>
      <div class="title">{{ $t('feed.dashboard.title') }}</div>
      <div class="filters">
        <DashboardQuickFilters :filters="dashboardFilters" @doFilter="applyFilter" />
      </div>
      <div class="mode">
        <ChoicesStyled>
          <button @click="setDashboardsViewMode('list')" :isActive="isListView" :class="{ active: isListView }">
            <ListIcon />
          </button>
          <button @click="setDashboardsViewMode('grid')" :isActive="isGridView" :class="{ active: isGridView }">
            <GridIcon />
          </button>
        </ChoicesStyled>
      </div>
    </HeaderStyled>
    <DashboardList
      :dashboards="filteredDashboards"
      :isLoading="$apollo.loading"
      :hasDashboardShareFeature="hasDashboardShareFeature"
      :machinesRecordCount="machinesRecordCount"
      v-if="isListView"
    />
    <DashboardGrid
      :dashboards="filteredDashboards"
      :isLoading="$apollo.loading"
      :hasDashboardShareFeature="hasDashboardShareFeature"
      v-else
    />
    <ActionsStyled>
      <ButtonStyled v-if="dashboardWrite" @click="$root.$emit('activateOverlay', 'DashboardSettingsOverlay', { mode: 'add' })">
        <PlusIcon />
      </ButtonStyled>
    </ActionsStyled>
  </DashboardsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'

import { PlusIcon, ListIcon, GridIcon } from 'vue-feather-icons'

import DashboardQuickFilters from '@/components/Atomic/Organisms/Dashboards/DashboardQuickFilters'

import { flexCenter, buttonReset } from '@styles/mixins'

import { ButtonRound } from '@styles/buttons'

import DashboardList from './Dashboards/DashboardList'
import DashboardGrid from './Dashboards/DashboardGrid'

import { getUserIdFromToken, getRolesFromToken } from '@common/utils'

import { useAssetStore } from '@/stores/assets'

import DASHBOARDS_LIST_QUERY from '#/graphql/operations/dashboards/dashboardsList.gql'
import DASHBOARDS_VIEW_MODE_QUERY from '#/graphql/local/dashboardsViewMode.gql'
import ORGANZATIONS_QUERY from '#/graphql/organizations/organizations.gql'
import MACHINE_RECORD_COUNT_QUERY from '#/graphql/operations/assets/machinesRecordCount.gql'

import ASSETS_MINIMAL_QUERY from '#/graphql/operations/assets/minimals/assetsMinimal.gql'
import MY_ASSETS_SITE_QUERY from '#/graphql/operations/assets/minimals/myAssetsSite.gql'
import OEMS_QUERY from '#/graphql/operations/assets/minimals/oems.gql'
import MY_SUBSIDIARIES_WITH_ASSETS_QUERY from '#/graphql/operations/subsidiaries/mySubsidiariesWithAssetsQuery.gql'

const ButtonStyled = styled(ButtonRound)`
  width: 40px;
  height: 40px;
`

const DashboardsStyled = styled('div')`
  max-width: 100%;
  margin: 1rem;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1rem;
  @media (max-width: 768px) {
    margin: 0.5rem;
  }
`

const ActionsStyled = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  bottom: 1rem;
`

const HeaderStyled = styled('div')`
  display: grid;
  grid-template-areas: 'title filters mode';
  grid-template-columns: auto 1fr 60px;
  align-items: center;
  justify-content: space-between;
  .title {
    grid-area: title;
    margin-right: 4rem;
    text-transform: capitalize;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.atomic.textMain};
  }
  .filters {
    grid-area: filters;
  }
  .mode {
    grid-area: mode;
  }
  @media (max-width: 768px) {
    grid-template-areas:
      'title mode'
      'filters filters';
    grid-template-columns: 1fr 60px;
    grid-template-rows: 60px auto;
  }
`

const ChoicesStyled = styled('div')`
  ${flexCenter}
  padding: .25rem;
  background: ${p => p.theme.colors.solidBG};

  button {
    ${buttonReset}
    padding: .25rem;
    height: 1.5rem;
    &:hover,
    &.active {
      color: ${p => p.theme.colors.primary};
    }
  }

  .feather {
    width: 1rem;
    height: 1rem;
  }
`

export default {
  inject: ['permissions'],
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  components: {
    DashboardsStyled,
    DashboardQuickFilters,
    ActionsStyled,
    ButtonStyled,
    PlusIcon,
    HeaderStyled,
    ChoicesStyled,
    ListIcon,
    GridIcon,
    DashboardList,
    DashboardGrid,
  },
  data() {
    return {
      dashboards: [],
      dashboardsViewMode: 'grid',
      selectedFilterId: 'all',
      userId: getUserIdFromToken(this.$keycloak.token),
      queriesLoaded: {
        assets: false,
        myAssetsSite: false,
        oems: false,
        mySubsidiaries: false,
      },
      mySubsidiaries: [],
      assetsSiteMap: new Map(),
      oemsMap: new Map(),
      assets: [],
    }
  },
  watch: {
    allQueriesLoaded(newVal) {
      if (newVal) {
        this.setAssetsWithCategoriesToStore()
      }
    },
  },
  computed: {
    isListView() {
      return this.dashboardsViewMode === 'list'
    },
    isGridView() {
      return this.dashboardsViewMode === 'grid'
    },
    dashboardWrite() {
      return get(this.permissions, 'write', []).find(p => p.name === 'dashboard_write')
    },
    dashboardFilters() {
      return [
        {
          isSelected: this.selectedFilterId === 'all',
          name: this.$t('dashboard.all'),
          count: '' + this.dashboards.length,
          id: 'all',
        },
        {
          isSelected: this.selectedFilterId === 'owner',
          name: this.$t('dashboard.sharedByYou'),
          count: '' + this.dashboards.filter(d => d.isOwner && d.shares.length > 0).length,
          id: 'owner',
        },
        {
          isSelected: this.selectedFilterId === 'not-owner',
          name: this.$t('dashboard.sharedByOther'),
          count: '' + this.dashboards.filter(d => !d.isOwner).length,
          id: 'not-owner',
        },
      ]
    },
    filteredDashboards() {
      if (this.selectedFilterId === 'owner') {
        return this.dashboards.filter(d => d.isOwner && d.shares.length > 0)
      } else if (this.selectedFilterId === 'not-owner') {
        return this.dashboards.filter(d => !d.isOwner)
      } else {
        return this.dashboards
      }
    },
    userRoles() {
      return getRolesFromToken(this.$keycloak.token)
    },
    isArchonorTalpaProduct() {
      return this.userRoles.includes('archon') || this.userRoles.includes('talpa-product')
    },
    hasDashboardShareFeature() {
      if (!this.isArchonorTalpaProduct) {
        if (this.organizations) {
          const orgsWithDashboardSharing = this.organizations.filter(org => {
            const userMemebership = get(org, 'memberships', []).find(membership => membership.userId === this.userId)
            const isAdmin = get(userMemebership, 'role', '') === 'ADMIN'
            const hasDashboardSharingFeature = Boolean(get(org, 'hasDashboardSharingFeature', false))
            const hasDashboardSharingForAll = Boolean(get(org, 'dashboardSharingEnabledForAllUsers', false))
            const isAdminWithDashboardSharingFeature = isAdmin && hasDashboardSharingFeature
            const isNonAdminWithDashboardSharingForAll = !isAdmin && hasDashboardSharingFeature && hasDashboardSharingForAll
            return isAdminWithDashboardSharingFeature || isNonAdminWithDashboardSharingForAll
          })
          return orgsWithDashboardSharing.length > 0
        }
        return false
      }
      return this.isArchonorTalpaProduct
    },
    allQueriesLoaded() {
      // Check if all values in queriesLoaded are true
      return Object.values(this.queriesLoaded).every(Boolean)
    },
    assetsWithUnknownCategories() {
      const availableAssets = this.assets
      const subsidiariesMap = new Map()

      // Pre-compute subsidiaries map
      this.mySubsidiaries?.forEach(sub => {
        sub.assets.forEach(subAsset => {
          if (!subsidiariesMap.has(subAsset.id)) {
            subsidiariesMap.set(subAsset.id, [])
          }
          subsidiariesMap.get(subAsset.id).push({ id: sub.id, name: sub.name })
        })
      })

      // Pre-compute values for unknown entities
      const unknownSite = { id: 'unknown', name: this.$tc('unknownSite', 1), __typename: 'AssetSite' }
      const unknownModel = { id: 'unknown', name: this.$t('unknownModel'), __typename: 'MachineModel' }
      const unknownOEM = { id: 'unknown', name: this.$t('unknownOEM'), alias: this.$t('unknownOEM'), __typename: 'OEM' }
      const unknownType = { id: 'unknown', name: this.$t('unknownType'), __typename: 'MachineType' }
      const unknownSubsidiary = [{ id: 'unknown', name: this.$t('unknownUsergroup'), __typename: 'Subsidiary' }]

      const mappedAssets = availableAssets.map(({ model, type, ...asset }) => {
        const oem = this.oemsMap.get(asset.manufacturerCuid) || unknownOEM
        const site = this.assetsSiteMap.get(asset.id)
          ? { ...this.assetsSiteMap.get(asset.id), id: this.assetsSiteMap.get(asset.id).siteId }
          : unknownSite
        const subsidiaries = subsidiariesMap.get(asset.id) || unknownSubsidiary

        return {
          subsidiary: subsidiaries,
          site,
          model: model || unknownModel,
          oem,
          type: type || unknownType,
          ...asset,
        }
      })

      return mappedAssets
    },
  },
  methods: {
    setDashboardsViewMode(mode) {
      this.dashboardsViewMode = mode

      const client = this.$apollo.provider.defaultClient
      client.writeQuery({
        query: DASHBOARDS_VIEW_MODE_QUERY,
        data: { dashboardsViewMode: this.dashboardsViewMode },
      })
    },
    applyFilter(filter) {
      this.selectedFilterId = filter.id
    },
    setAssetsWithCategoriesToStore() {
      const assetsWithUnknownDataFromStore = this.assetStore.getAssetsWithUnknownData()
      if (assetsWithUnknownDataFromStore.length) {
        return
      }
      const mappedAssets = this.assetsWithUnknownCategories
      this.assetStore.setAssetsWithUnknownData(mappedAssets)
    },
  },
  apollo: {
    machinesRecordCount: {
      query: MACHINE_RECORD_COUNT_QUERY,
    },
    dashboardsViewMode: {
      query: DASHBOARDS_VIEW_MODE_QUERY,
    },
    dashboards: {
      query: DASHBOARDS_LIST_QUERY,
      update({ dashboards }) {
        return dashboards.map(d => {
          return {
            ...d,
            isOwner: d.ownerUserId === this.$keycloak.subject,
          }
        })
      },
    },
    organizations: {
      query: ORGANZATIONS_QUERY,
      variables() {
        return {
          where: {
            OR: [
              {
                memberships_some: {
                  userId: getUserIdFromToken(this.$keycloak.token),
                  role: 'ADMIN',
                },
                hasDashboardSharingFeature: true,
              },
              {
                memberships_some: {
                  userId: getUserIdFromToken(this.$keycloak.token),
                  role: 'USER',
                },
                nonAdminUsersCanSeeOtherUsers: true,
                hasDashboardSharingFeature: true,
              },
            ],
          },
        }
      },
    },
    assets: {
      query: ASSETS_MINIMAL_QUERY,
      variables: {
        where: {
          isVisible: {
            in: [true],
          },
        },
      },
      result({ data }) {
        if (data.assets.length) {
          this.assetStore.allAssets = data?.assets ?? []
        }
        this.queriesLoaded.assets = true
      },
    },
    myAssetsSite: {
      query: MY_ASSETS_SITE_QUERY,
      result({ data }) {
        this.assetsSiteMap = new Map(data.myAssetsSite.map(asset => [asset.assetId, asset]))
        this.queriesLoaded.myAssetsSite = true
      },
    },
    oems: {
      query: OEMS_QUERY,
      result({ data }) {
        this.oemsMap = new Map(data.oems.map(oem => [oem.id, oem]))
        this.queriesLoaded.oems = true
      },
    },
    mySubsidiaries: {
      query: MY_SUBSIDIARIES_WITH_ASSETS_QUERY,
      result() {
        this.queriesLoaded.mySubsidiaries = true
      },
    },
  },
}
</script>
