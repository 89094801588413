<template>
  <PlainCellStyled
    class="cell"
    :isMobile="isMobile"
    :panel="cell.panel"
    @click="$root.$emit('activateOverlay', 'DashboardShareOverlay', cell.dashboard)"
  >
    {{ cell.label }}
  </PlainCellStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

const PlainCellStyled = styled('div')`
  display: flex;
  align-items: center;
  @media (min-width: 1024px) {
    padding-left: 0;
    ${({ isMobile, panel }) => !isMobile && !panel && `padding-left: 3rem`};
  }
  cursor: pointer;
  padding: 0.5rem;
`

export default {
  props: {
    cell: {
      type: Object,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    panel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPopup: '',
    }
  },
  components: {
    PlainCellStyled,
  },
}
</script>
